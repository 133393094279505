import {
  API_BASE_URL,
  API_CAMPANIAS,
} from "../../../../../../../@core/constants";
import { axiosCustom } from "../../../../../../../utility/AxiosIntercertor";
import { encodeHashids } from "../../../../../../../utility/Utils";

export const types = {
  SET_LOADING: "SET_LOADING",
  SET_LOADING_FILTER_CAMPANIA: "SET_LOADING_FILTER_CAMPANIA",
  SET_DATA_FILTER_PROP: "SET_DATA_FILTER_PROP",
  SET_REGIONES: "SET_REGIONES",
  SET_AGENCIAS: "SET_AGENCIAS",
  SET_CAMPANIAS: "SET_CAMPANIAS",
  SET_USUARIOS: "SET_USUARIOS",
  SET_FILTER_CAMPANIA: "SET_FILTER_CAMPANIA",
};

export const setLoading = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_LOADING,
      loading: data,
    });
  };
};

export const setDataFilterProp = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_DATA_FILTER_PROP,
      dataFilterProp: data,
    });
  };
};

export const getRegiones = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_LOADING,
      loading: true,
    });

    axiosCustom
      .get(API_BASE_URL + "/user/regionFilter", data)
      .then((response) => {
        let reg = [];
        response.data.forEach((element) => {
          element.value = element.codreg;
          element.label = element.region;
          reg.push(element);
        });

        dispatch({
          type: types.SET_REGIONES,
          regiones: reg,
        });
        dispatch({
          type: types.SET_LOADING,
          loading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.SET_LOADING,
          loading: false,
        });
        console.log("error", error);
      });
  };
};

export const getAgencias = () => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_LOADING,
      loading: true,
    });
    axiosCustom
      .get(API_BASE_URL + "/user/agenciaFilter")
      .then((response) => {
        let age = [];
        response.data.forEach((element) => {
          element.value = element.codage;
          element.label = element.agencia;
          age.push(element);
        });
        dispatch({
          type: types.SET_AGENCIAS,
          agencias: age,
        });
        dispatch({
          type: types.SET_LOADING,
          loading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.SET_LOADING,
          loading: false,
        });
        console.log("error", error);
      });
  };
};

export const getCampanias = () => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_LOADING,
      loading: true,
    });

    axiosCustom
      .get(API_BASE_URL + "/user/campaniasActivas")
      .then((response) => {
        let camp = [];
        response.data.forEach((element) => {
          element.value = element.id;
          element.label = element.nombre;
          camp.push(element);
        });
        dispatch({
          type: types.SET_CAMPANIAS,
          campanias: camp,
        });
        dispatch({
          type: types.SET_LOADING,
          loading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.SET_LOADING,
          loading: false,
        });
        console.log("error", error);
      });
  };
};

export const getUsuarios = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_LOADING,
      loading: true,
    });
    axiosCustom
      .get(API_BASE_URL + "/user/empleadosLvl")
      .then((response) => {
        let us = [];
        response.data.forEach((element) => {
          element.value = element.usuario;
          element.label = element.usuario;
          us.push(element);
        });
        dispatch({
          type: types.SET_USUARIOS,
          usuarios: us,
        });
        dispatch({
          type: types.SET_LOADING,
          loading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.SET_LOADING,
          loading: false,
        });
        console.log("error", error);
      });
    dispatch({
      type: types.SET_USUARIOS,
      usuarios: data,
    });
  };
};

export const setFilterCampania = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_FILTER_CAMPANIA,
      filterCampania: data,
    });
  };
};

export const getFilterCampania = (campania, monitorId) => {
  const data = {
    randomk: encodeHashids(campania),
    randomg: encodeHashids(monitorId),
  };

  return async (dispatch) => {
    dispatch({
      type: types.SET_LOADING_FILTER_CAMPANIA,
      loading: true,
    });
    axiosCustom
      .post(API_CAMPANIAS + "/campanias/filterGlobal", data)
      .then((response) => {
        //dispatch(setFilterCampania(response.data));
        dispatch({
          type: types.SET_FILTER_CAMPANIA,
          filterCampania: response.data,
        });
        dispatch({
          type: types.SET_LOADING_FILTER_CAMPANIA,
          loading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.SET_LOADING_FILTER_CAMPANIA,
          loading: false,
        });
        console.log("error", error.response);
      });
  };
};
